import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import { makeStyles } from '@material-ui/styles';
import "../assets/css/pricingpage.css";
import DOMPurify from "dompurify"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const useStyles = makeStyles({
    root: {
      width: 1000,
      height: 850,
    },
  });
  

const PricingPage = () => {

    const classes = useStyles();
    const data = useStaticQuery(query);
    //const homepageImage = getImage(data.strapiHomepage.seo.WelcomeImage)

    return (
        <Layout>        
            <div className="uk-section">
                <div className="uk-container uk-container-large">
                    <div className="pricing-wrapper">
                        <div className="pricing-container">
                            {/* <p dangerouslySetInnerHTML={{ __html: data.strapiPricingPage.PricingDescription}}></p> */}
                            {data.strapiPricingPage.cookie_types.map((item) => (
                                <div className="package-type" key={item.id}>
                                    <div className="package-description">
                                        <label className="package-name">{item.CookieType}</label>
                                        <label dangerouslySetInnerHTML={{ __html: item.CookieDescription}}></label>
                                        {/* <label>{item.CookieDescription}</label> */}
                                    </div>
                                    <div className="package-details">
                                        {/* <label><b>Flavors:</b> Up to {item.Flavors}</label>       */}
                                        <label><b>Colors:</b> Up to {item.NumberOfColors}</label>
                                        <label><b>Designs:</b> Up to {item.NumberOfDesigns}</label>                                        
                                        <label>{item.MinimumRequired}</label>
                                    </div>
                                    <div className="package-price">
                                    {/* <object data="http://stackoverflow.com/does-not-exist.png" type="image/png">
                                        <img src="https://cdn.sstatic.net/Img/unified/sprites.svg?v=e5e58ae7df45" alt="Stack Overflow logo and icons and such" />
                                    </object> */}                                     
                                        { item.CookieExample !== null && <img className="cookie-example" src={ item.CookieExample.url } alt="No Media"></img>}       
                                        <label>${item.CookiePrice} / doz.</label>                                                
                                    </div>
                                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

const query = graphql`
    query {   
        strapiPricingPage {
            id
            PricingDescription
            cookie_types{
                Flavors
                id
                CookieType
                CookiePrice
                CookieDescription
                NumberOfColors
                NumberOfDesigns
                CookieExample {
                    url
                }
            }
        }
    }
`;

export default PricingPage;